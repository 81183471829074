<!--电梯模块--关联设备时间表-->
<template>
  <div id="timetablemodule"  ref="main">
    <transition name="slide-fade" v-if="data.length>0">
      <div class="timetablemodule-row-list">
        <template  v-for="item in data">
          <div id="timetablemodule-card" :key="item.id">
            <div v-if="false">
              <a-icon
                type="clock-circle"
                :style="{ fontSize: '24px', color: '#7682CE', 'margin': '10px 10px' }"
              />
            </div>
            <div>
              <img :src="getImages" />
            </div>
            <div class="timetablemodule-card-title">{{ item.name }}</div>
            <div class="timetablemodule-card-footer">
              <div style="flex-grow:1;">
                <a-switch v-model="item.mode" size="small" @change="hanlderApplice(item)"/>
              </div>
              <a-tooltip :title="$t('timedeviceModule.a10')" color="#7682CE">
                <a :style="{ 'margin-right': '10px', 'margin-top': '2px' }" @click="showConnectModal(item)">
                {{ $t('timedeviceModule.a2') }}
                </a>
              </a-tooltip>
            </div>
          </div>
        </template>
        <div class="empty" v-for="n in 10" :key="'m'+n"></div>
      </div>
    </transition>
    <transition name="slide-fade" v-else>
      <div class="empty-show">
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px;margin-top: 10%;" />
        <span>{{ $t('timedeviceModule.a3') }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import showTime from "../components/showTime";
import elevatorTtDialog from "../components/elevatorTtDialog";
import moment from 'moment';
import { 
  setTimeIntervalsBatchToDevices,
  deleteTimeInterval
} from "../../../api/elevator";
export default {
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    moment,
    // 弹出解绑警告
    showConnectModal(data){
      if (!data.mode) {
        this.$confirm({
          title: this.$t('timedeviceModule.a4'),
          centered: true,
          onOk: () => {
            this.loading = true;
            deleteTimeInterval(data.id)
              .then((res) => {
                if (res.code == 200) {
                  this.$parent.getDeviceData();
                  this.$message.success(this.$t('timedeviceModule.a5'));
                } else{
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
            });
          },
        })
      } else {
        this.$error({
          title: this.$t('timedeviceModule.a6'),
          centered: true,
          onOk: () => {},
        });
      }
    },
    // 启用或停用
    hanlderApplice(item){
      setTimeIntervalsBatchToDevices(this.id,item.mode?255:0,[item.mcid]).then((res)=>{
        if(res.code === 200){
           this.$parent.getDeviceData();
           this.$success({
            title: this.$t('timedeviceModule.a7'),
            centered: true,
          });
        } else{
          this.$error({
            title: item.mode ?this.$t('timedeviceModule.a8'):this.$t('timedeviceModule.a9'),
            centered: true,
            content: res.msg
          });
          item.mode = !item.mode;
        }
      }).catch((err)=>{
        console.log(err)
      })
    },
    // 取消解除绑定关系
    handleCancel(){
      this.connectVisible = false;
    },
    // 确认解除绑定关系
    hideConnectModal(){
      this.connectVisible = false;
    },
  },
  components: {
    "my-showtime": showTime,
    "my-dialog": elevatorTtDialog,
  },
  computed: {
    // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      return require("../../../../public/images/doorIconNo/246.png");
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
#timetablemodule {
  width: 100%;
}
.timetablemodule-row-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction:row;
  justify-content: space-between;
  margin-bottom: 0px;
}
#timetablemodule-card {
  width: 200px;
  height: 110px;
  background-color: #f0f5f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
}
#timetablemodule-card:last-child{
    margin-right:auto;
  }
#timetablemodule-card:hover {
  border: 1px solid #7682ce;
}
#timetablemodule-card .timetablemodule-card-title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: #999;
}
#timetablemodule-card .timetablemodule-card-footer {
  width: 100%;
  /* padding-top: 4px; */
  padding-left: 10px;
  line-height:24px;
  display: flex;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.empty-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
</style>