<!--门禁模块--门禁时间表-->
<template>
  <div id="timetablemodule" ref="main">
    <!-- 1 -->
    <!-- 2 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t('timetableModule.a12') }}</div>
      <div class="timetablemodule-row-two">
        <span style="font-size:15px;"> {{ moment(data.mti_date_start).format(`YYYY`+ $t('timetableModule.a16') + 'MM'+ $t('timetableModule.a17') + 'DD' + $t('timetableModule.a18')) }} {{ $t('timetableModule.a19') }} {{ moment(data.mti_date_end).format(`YYYY`+ $t('timetableModule.a16') + 'MM'+ $t('timetableModule.a17') + 'DD' + $t('timetableModule.a18')) }} </span>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="hanldEditDate"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 3 -->
    <div class="timetablemodule-row" v-if="data.ls_mti_week">
      <div class="timetablemodule-row-one">{{ $t('timetableModule.a9') }}</div>
      <div class="timetablemodule-row-two">
        <a-tag color="#7682ce" v-if="data.ls_mti_week[0]===1">{{ $t('timetableModule.a2') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[1]===1">{{ $t('timetableModule.a3') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[2]===1">{{ $t('timetableModule.a4') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[3]===1">{{ $t('timetableModule.a5') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[4]===1">{{ $t('timetableModule.a6') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[5]===1">{{ $t('timetableModule.a7') }}</a-tag>
        <a-tag color="#7682ce" v-if="data.ls_mti_week[6]===1">{{ $t('timetableModule.a8') }}</a-tag>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="hanldEditWeek"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 4 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t('timetableModule.a10') }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="canvasData"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime()"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 时间表有效日期设置弹窗-->
    <a-modal
      :visible="dateVisible"
      :title="$t('timetableModule.a11')"
      :width="300"
      centered
      :destroyOnClose="true"
      @cancel="amendCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="amendCancel">
          {{ $t('timetableModule.a14') }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="updateDate"
        >
          {{ $t('timetableModule.a13') }}
        </a-button>
      </template>
      <div
        v-loading="dialogloaing"
        :element-loading-text="$t('timetableModule.a1')"
        class="modelName"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          layout="horizontal"
          :rules="rules"
        >
          <a-form-model-item :label="$t('timetableModule.a12')" prop="limitdate">
            <a-range-picker
              :ranges="{ '今天': [moment(), moment()], '当月': [moment(), moment().endOf('month')], '当年': [moment(), moment().endOf('year')], '永久': [moment(), moment('2099-12-31 23:59:59')] }"
              v-model="form.limitdate">
            </a-range-picker>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 时间表星期设置弹窗-->
    <a-modal
      :visible="weekVisible"
      :title="$t('timetableModule.a15')"
      :width="380"
      centered
      :destroyOnClose="true"
      @cancel="amendCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="amendCancel">
          {{ $t('timetableModule.a14') }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="updateWeek"
        >
          {{ $t('timetableModule.a13') }}
        </a-button>
      </template>
      <div
        v-loading="dialogloaing"
        :element-loading-text="$t('timetableModule.a1')"
        class="modelName"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          layout="horizontal"
          :rules="rules"
        >
          <a-form-model-item :label="$t('timetableModule.a15')" prop="limitdate">
            <a-checkbox-group v-model="form.week" :options="weekOptions" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 时间表设置弹窗-->
    <my-dialog
      :visible="visible"
      :data="dialogData"
    ></my-dialog>
  </div>
</template>

<script>
import showTime from "../components/showTime";
import elevatorTtDialog from "../components/elevatorTtDialog";
import moment from 'moment';
import { isEmptrValue,setTimeInterval } from "../../../api/elevator";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      dialogData: {},
      canvasData: {},
      week: 0,
      form:{
        limitdate:[moment(this.data.mti_date_start),moment(this.data.mti_date_end)],
        week: []
      },// 表单数据
      dateVisible:false,// 日期弹窗
      rules: {
        limitdate: [{ required: true, message: '请选择有效期限', trigger: 'change' }],
        week: [{ required: true, message: '请勾选适用星期', trigger: 'blur' }]
      },
      dialogloaing: false,
      weekVisible: false,
      weekOptions:[
        { label: this.$t('timetableModule.a2'), value: 0 }, 
        { label: this.$t('timetableModule.a3'), value: 1 }, 
        { label: this.$t('timetableModule.a4'), value: 2 }, 
        { label: this.$t('timetableModule.a5'), value: 3 }, 
        { label: this.$t('timetableModule.a6'), value: 4 }, 
        { label: this.$t('timetableModule.a7'), value: 5 }, 
        { label: this.$t('timetableModule.a8'), value: 6 }
      ],
      openData: {
        schedule1: {
          begin: '08:00',
          end: '13:00',
          model:0
        },
        schedule2: {
          begin: '00:00',
          end: '00:00',
          model:0
        },
        schedule3: {
          begin: '00:00',
          end: '00:00',
          model:0
        },
        schedule4: {
          begin: '00:00',
          end: '00:00',
          model:0
        }
      },
    };
  },
  methods: {
    moment,
    // 改变对话框状态的方法
    closeDialog() {
      this.visible = false;
    },
    // 加载页面数据的方法
    getData() {
      this.$emit("getData");
    },
    // 点击修改icon时加载对应的数据和修改对话框状态
    getTime() {
      this.visible = true;
      this.dialogData = this.data;
    },
    // 点击修改icon时加载有效日期设置对话框
    hanldEditDate(){
      this.form.limitdate = [moment(this.data.mti_date_start),moment(this.data.mti_date_end)];
      this.dateVisible = true;
    },
    // 关闭有效日期设置对话框
    amendCancel(){
      this.dateVisible = false; // 关闭有效日期设置对话框
      this.weekVisible = false; // 关闭星期设置对话框
    },
    // 设置有效日期
    updateDate(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = this.data
          data.mti_date_start = moment(this.form.limitdate[0]);
          data.mti_date_end = moment(this.form.limitdate[1]);
          setTimeInterval(data).then( res => {
            if(res.code === 200){
              setTimeout(() => {
                this.dateVisible = false;
              }, 5);
            }else {
            }
          })} else {
              return false;
        }
      });
    },
    // 点击修改icon时加载有效日期设置对话框
    hanldEditWeek(){
      this.form.week = []
      for (let i = 0; i < this.data.ls_mti_week.length; i++) {
        if(this.data.ls_mti_week[i] === 1){
          this.form.week.push(i)
        }else{

        }
      }
      this.weekVisible = true;
    },
    // 设置有效日期
    updateWeek(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = this.data
          const temp = [0, 0, 0, 0, 0, 0, 0]
          if (this.form.week.length !== 0) {
            for (let i = 0; i < this.form.week.length; i++) {
              temp[this.form.week[i]] = 1
          }
          }
          data.ls_mti_week = temp
          setTimeInterval(data).then( res => {
            if(res.code === 200){
              setTimeout(() => {
                this.weekVisible = false;
              }, 5);
            }else {
            }
          })} else {
              return false;
        }
      });
    },
  },
  components: {
    "my-showtime": showTime,
    "my-dialog": elevatorTtDialog,
  },
   watch: {
    // 操作修改显示时间数据
    data: {
      handler(newVal, oldVal) {
        const blo = newVal.mti_time1_startStr;
        if(blo !== undefined){
          let data = JSON.stringify(this.data);
          let _data = {
            schedule1: {
              begin: [this.data.mti_time1_startStr.split(":")[0],this.data.mti_time1_startStr.split(":")[1]],
              end: [this.data.mti_time1_endStr.split(":")[0],this.data.mti_time1_endStr.split(":")[1]],
              model:0
            },
            schedule2: {
              begin: [this.data.mti_time2_startStr.split(":")[0],this.data.mti_time2_startStr.split(":")[1]],
              end: [this.data.mti_time2_endStr.split(":")[0],this.data.mti_time2_endStr.split(":")[1]],
              model:0
            },
            schedule3: {
              begin: [this.data.mti_time3_startStr.split(":")[0],this.data.mti_time3_startStr.split(":")[1]],
              end: [this.data.mti_time3_endStr.split(":")[0],this.data.mti_time3_endStr.split(":")[1]],
              model:0
            },
            schedule4: {
              begin: [this.data.mti_time4_startStr.split(":")[0],this.data.mti_time4_startStr.split(":")[1]],
              end: [this.data.mti_time4_endStr.split(":")[0],this.data.mti_time4_endStr.split(":")[1]],
              model:0
            },
          }
          this.openData = _data;
        }
      },
      deep: true,
    },
    // 渲染时间条
    openData: {
      handler(newVal, oldVal) {
        for(let i in newVal){
          if(newVal[i].begin[0] == '24'){
            newVal[i].begin[1] = '00'
          }
          if(newVal[i].end[0] == '24'){
            newVal[i].end[1] = '00'
          }
        }
        let data = JSON.stringify(this.openData)
        let _data = JSON.parse(data)
        for(let i in _data){
          _data[i].begin = _data[i].begin[0] + ':' +  _data[i].begin[1]
          _data[i].end = _data[i].end[0] + ':' + _data[i].end[1]
        }
        this.canvasData = _data;
      },
      deep: true,
    },
  },
};
</script>

<style>
#timetablemodule {
  width: 100%;
}
.timetablemodule-row {
  width: 100%;
  height: 90px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #fff;
  box-shadow: 0 0px 5px #bdbcbc;
  border-radius: 3px;
}
.timetablemodule-row-one {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-right: 1px solid #e7e8e8;
}
.timetablemodule-row-two {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  padding: 0px 30px;
}
.timetablemodule-row-three {
  width: 70px;
  display: flex;
  align-items: center;
}
.timetablemodule-row-list{
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 15px;
}
#timetablemodule-card {
  width: 200px;
  height: 110px;
  background-color: #f0f5f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
}
#timetablemodule-card:hover {
  border: 1px solid #7682ce;
}
#timetablemodule-card .title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: #999;
}
#timetablemodule-card .footer {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 4px;
  padding-left: 10px;
  line-height:24px;
}
</style>