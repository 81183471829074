<!--电梯模块--电梯时间表-->
<template>
  <div
    id="elevatorTimetable"
    v-loading="loading"
    :element-loading-text="$t('elevatorTimetable.a1')"
  >
    <div class="aside">
      <div class="aside-action">
        <a-button @click="addHandle" type="primary" block>{{$t('elevatorTimetable.a2')}}</a-button>
      </div>
      <div class="aside-menu">     
         <my-navder :list="list" @select="getMsgFormSon"></my-navder>
       </div>
    </div>
    <div class="right">
      <div class="main" ref="main" v-if="timeid">
        <my-headertitle>
          {{ type=='a'? $t('elevatorTimetable.a3'): $t('elevatorTimetable.a4') }}
          <template v-slot:name >
            <a-radio-group button-style="solid" v-model="type">
              <a-radio-button value="a">{{ $t('elevatorTimetable.a5') }}</a-radio-button>
              <a-radio-button value="b">{{ $t('elevatorTimetable.a6') }}</a-radio-button>
            </a-radio-group>
          </template>
        </my-headertitle>
         <transition name="slide-fade">
           <div v-if="type == 'a'">
            <div class="info">
              <div>
                <span>{{ $t("elevatorTimetable.a7") }}：</span>
                <span>{{ name }}</span>
                <a-icon
                  type="form"
                  @click="openNameUpdate"
                  :style="{
                    fontSize: '15px',
                    color: '#7682CE',
                    'margin-left': '15px',
                  }"
                />
              </div>
              <div>
                <ul class="templatetitle-right">
                  <li>
                    <div class="upright" style="background: #0fce0f"></div>
                    {{ $t("elevatorTimetable.a8") }}
                  </li>
                  <li>
                    <div class="upright" style="background: #f8b62d"></div>
                    {{ $t("elevatorTimetable.a9") }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- 时间界面列表 -->
            <my-timetable
              v-if="type == 'a'"
              :data="data"
              :id="timeid"
              @getData="getData"
            ></my-timetable>
           </div>
         </transition>
         <transition name="slide-fade">
          <div v-if="type == 'b'">
            <div class="info">
              <div>
                <span>{{ $t("elevatorTimetable.a7") }}：</span>
                <span>{{ name }}</span>
                <a-icon
                  type="form"
                  @click="openNameUpdate"
                  :style="{
                    fontSize: '15px',
                    color: '#7682CE',
                    'margin-left': '15px',
                  }"
                />
              </div>
              <div>
                <ul class="templatetitle-right">
                  <li>
                    <div class="upright" style="background: #13ce66;"></div>
                    {{ $t("elevatorTimetable.a10") }}
                  </li>
                  <li>
                    <div class="upright" style="background: rgba(0, 0, 0, 0.25)"></div>
                    {{ $t("elevatorTimetable.a11") }}
                  </li>
                </ul>
              </div>
            </div>
            <my-timedevice
              :id="timeid"
              :data="connectdeviceslist"
              @getList="getDeviceData"
            ></my-timedevice>
          </div>
         </transition>
      </div>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{ $t("elevatorTimetable.a12") }}</span>
      </div>
      <div class="footer" v-if="timeid">
        <a-button type="primary" @click="allCopyTt" v-if="type == 'a'">{{ $t("elevatorTimetable.a13") }}</a-button>
        <a-button type="primary" v-if="type == 'b'"  @click="showConnectModel">{{ $t("elevatorTimetable.a14") }}</a-button>
        <a-button type="primary" v-if="type == 'b'" @click="hanlderApplice" :disabled="connectdeviceslist.length === 0">{{ $t("elevatorTimetable.a15") }}</a-button>
      </div>
    </div>
    <!-- 时间表添加 -->
    <a-modal
      :title="$t('elevatorTimetable.a16')"
      :width="320"
      centered
      :visible="visible1"
      :destroyOnClose="true"
      @cancel="addHandleCancel"
      v-drag
    >
      <template slot="footer">
        <a-button  @click="addHandleOk" type="primary" key="submit">
          {{ $t("elevatorTimetable.a18") }}
        </a-button>
        <a-button
          key="back"
          @click="addHandleCancel"
          :disabled="disabled"
        >
          {{ $t("elevatorTimetable.a19") }}
        </a-button>
      </template>
      <div v-loading="loading_times" :element-loading-text="$t('elevatorTimetable.a1')">
        <a-form-model
          ref="ruleForm"
          :model="form"
          layout="horizontal"
          :rules="rules"
        >
          <a-form-model-item :label="$t('elevatorTimetable.a7')" prop="name">
            <a-input
              v-model="form.name"
              allow-clear
              :maxLength="30"
              ref="input"
              :placeholder="$t('elevatorTimetable.a17')"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 时间表复制 -->
    <a-modal
      :title="$t('elevatorTimetable.a13')"
      :width="360"
      centered
      :visible="visible2"
      :destroyOnClose="true"
      @cancel="handleCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="handleCancel" :disabled="disabled">
          {{ $t("elevatorTimetable.a19") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="handleOk"
          :disabled="disabled"
        >
          {{ $t("elevatorTimetable.a18") }}
        </a-button>
      </template>
      <div
        v-loading="loading_time"
        :element-loading-text="$t('elevatorTimetable.a1')"
      >
        <div>
          <span>{{ $t("elevatorTimetable.a20") }}:</span>
          <a-select
            :placeholder="$t('elevatorTimetable.a12')"
            v-model="srcDay"
            style="width: 220px; margin: 0px 20px"
          >
            <a-select-option v-for="item in list[0].children" :key="item.key">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <span>{{ $t("elevatorTimetable.a21") }}:</span>
          <a-select
            :placeholder="$t('elevatorTimetable.a12')"
            v-model="decDay"
            style="width: 220px; margin: 10px 20px"
          >
            <a-select-option v-for="item in list[0].children" :key="item.key">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
    <!-- 时间表名称更新 -->
    <a-modal
      :visible="nameVisible"
      :title="$t('elevatorTimetable.a22')"
      :width="320"
      centered
      :destroyOnClose="true"
      @cancel="amendCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="amendCancel" :disabled="disabled">
          {{ $t("elevatorTimetable.a19") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="updateName"
          :disabled="disabled"
        >
          {{ $t("elevatorTimetable.a18") }}
        </a-button>
      </template>
      <div
        v-loading="nameloaing"
        :element-loading-text="$t('elevatorTimetable.a1')"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          layout="horizontal"
          :rules="rules"
        >
          <a-form-model-item :label="$t('elevatorTimetable.a7')" prop="name">
            <a-input
              v-model="form.name"
              allow-clear
              :maxLength="30"
              ref="input"
              :placeholder="$t('elevatorTimetable.a17')"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
     <!-- 设备批量关联 -->
    <a-modal
      :visible="connectVisible"
      :title="$t('elevatorTimetable.a23')"
      :width="300"
      centered
      :destroyOnClose="true"
      @cancel="hideConnectModal"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="hideConnectModal" :disabled="disabled">
          {{ $t("elevatorTimetable.a19") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="updateConnect"
          :disabled="disabled"
        >
          {{ $t("elevatorTimetable.a18") }}
        </a-button>
      </template>
      <div
        v-loading="connectloaing"
        :element-loading-text="$t('elevatorTimetable.a18')"
        class="modelConnect"
      >
        <a-form-model
          ref="ruleForm"
          :model="connectForm"
          layout="horizontal"
          :rules="connectRules"
        >
          <a-form-model-item :label="$t('elevatorTimetable.a24')" prop="name">
            <a-select
              :placeholder="$t('elevatorTimetable.a25')"
              v-model="connectForm.devices"
              mode="multiple"
              :options="deviceslist"
              :max-tag-count = "1"
            >
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import tabletitle from "../../components/Title/tabletitle";
import Navdar from "./Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import timetableModule from "./components/timetableModule";
import timedeviceModule from "./components/timedeviceModule";
import { 
  isEmptrValue,
  getbuildsiteId,
  getTimeIntervalList,
  getTimeInterval,
  getTimeIntervalNextID,
  addTimeInterval,
  setTimeInterval,
  getApiDeviceList,
  getConnectDeviceByTimeInterval,
  putConnectDeviceByTimeInterval,
  setTimeIntervalsBatchToDevices
} from "../../api/elevator";
export default {
  name: "elevatorTimetable",
  data() {
    return {
      id:{
        client:0,
        area:0,
        build:0
      }, // 获取梯控对应的id值
      timeid: 0, // 时间表id
      newtime: 0, // 新建时间表ID
      type:'a',
      name: "",
      list: [{ title: "电梯时间表",key: 0,children:[]}],
      form: {
        id: 0,
        num: 0,
        name: '',
        week: [1,1,1,1,1,0,0]
      }, // 时间表单
      data: undefined, // 时间表数据
      loading: false,
      loading_times: false,            
      visible1: false,
      visible2: false, // 时间表复制
      disabled: false,
      srcDay: undefined, // 复制表-来源表
      decDay: undefined, // 复制表-目标表
      loading_time: false,  //复制表-加载
      nameVisible: false, // 时间表名称更新-弹窗
      nameloaing: false,  // 时间表名称更新-加载
      rules: {
        name: [
          {
            required: true,
            message: this.$t("doortimetable.a45"),
            trigger: "change",
          },
        ],
      },
      connectRules:{},
      connectVisible: false,
      connectForm:{
        devices:[]
      },
      deviceslist:[
        { key:1,title:'1号控制器'},
        { key:2,title:'2号控制器'},
        { key:3,title:'3号控制器'},
        { key:4,title:'4号控制器'},
        { key:5,title:'5号控制器'},
        { key:6,title:'6号控制器'},
        { key:7,title:'7号控制器'},
        { key:8,title:'8号控制器'},
        { key:9,title:'9号控制器'},
        { key:10,title:'10号控制器'},
        { key:11,title:'11号控制器'},
        { key:12,title:'12号控制器'},
        { key:13,title:'13号控制器'},
        { key:14,title:'14号控制器'},
        { key:15,title:'15号控制器'},
      ],
      connectloaing:false,
      connectdeviceslist: [
      ]
    };
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    timeid: function (newQuestion, oldQuestion) {
      this.getData()
      this.getDeviceData()
    },
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    // this.navderList();
  },
  methods: {
    moment,
    // 获取页面左侧列表方法
    navderList() {
      this.loading = true;
      const listtemp = [{ title: this.$t('elevatorTimetable.a3'),key: 0,children:[]}]
      const params = {'mti_areaId': this.id.area,'mti_name':"null"}
      getTimeIntervalList(params).then((res)=>{
          if(res.code===200){
            res.data.forEach(
              item =>{
                const model = {key:item.mti_ID+'', title:item.mti_name}
                listtemp[0].children.push(model)
                }
            )
            this.list =  listtemp
          }else{
            this.list =  listtemp 
          }
          this.loading = false;
        }
      ).catch((err) => {
        this.loading = false;
      });
    },
    getdeviceList(){
      getApiDeviceList({'mc_areaID':this.id.area,'mc_clientID':this.id.client}).then((res) => { 
          const tempList = []     
          res.data.list.forEach(
            item =>{
              const temp = { "key": item.mc_ID, "title": item.mcName }
              tempList.push(temp)
          })
          this.deviceslist = tempList
      })
      .catch((err) => {
        console.log("出错", err);
        this.loading = false;
      });
    },
    // 加载页面数据的方法
    getData() {
      this.loading = true;
      getTimeInterval(this.timeid)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          this.name = data.mti_name;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 加载页面关联设备数据的方法
    getDeviceData() {
      this.loading = true;
      getConnectDeviceByTimeInterval(this.timeid)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          let list = [];
          res.data.forEach(item => {
             let model = { 
               id: item.mcInTimeInterval.id, 
               name: item.bindMcInfo.mcName, 
               mode: item.mcInTimeInterval.mti_ID_sent != 0,
               mcid: item.mcInTimeInterval.mc_ID, 
               timeid:item.mcInTimeInterval.mti_ID
             };
             list.push(model);    
          })
          this.connectdeviceslist = list;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 打开时间表名称更新对话框
    openNameUpdate() {
      this.nameVisible = true;
      this.form.name = this.name;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.nameVisible = false;
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.timeid = data;
    },
    // 页面更新name成功后更新类表名字
    alterName(arr) {
      let id = this.id;
      let name = this.form.name;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key == id) {
          arr[i].title = name;
        }
        if (arr[i].children) {
          this.alterName(arr[i].children);
        }
      }
    },
    // 更新时间表名称
    updateName() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.data.mti_name = this.form.name;
          setTimeInterval(this.data).then( res => {
            if(res.code === 200){
              this.navderList();
              this.name = this.form.name;
              setTimeout(() => {
                this.nameVisible = false;
              }, 5);
            }else {
            }
          })} else {
              return false;
        }
      });
    },
    // *****start****** 复制时间表 ***********
    // 时间表复制(整表复制)
    allCopyTt() {
      this.srcDay = this.timeid;
      this.visible2 = true;
    },
    // 取消时间表复制(整表复制)
    handleCancel() {
      this.visible2 = false;
    },
    // 时间表复制确认
    handleOk () {
      this.loading_time = true;
      getTimeInterval(this.srcDay)
        .then((res) => {
          let data = res.data;
          data.mti_ID = parseInt(this.decDay);
          this.list[0].children.forEach( item => {
            if(item.key === this.decDay)
            {
              data.mti_name = item.title
            }
          })
          setTimeInterval(data)
            .then((res) => {
              this.loading_time = false;
              if(res.code === 200){
                this.navderList()
                this.visible2 = false;
                setTimeout(() => {
                  this.$success({
                  title: this.$t('elevatorTimetable.a26'),
                  centered: true,
                });
                }, 5);
              }
              else{
                this.$error({
                  title: this.$t('elevatorTimetable.a27'),
                  centered: true,
                });
              }
            })
            .catch((err) => {
              this.loading_time = false;
          });
        })
        .catch((err) => {
          this.loading_time = false;
      });
    },
    // *****end****** 复制时间表 ***********
    // *****start****** 添加操作
    // 时间表添加
    addHandle(){
      // 获取时间表ID
      // 弹窗
      getTimeIntervalNextID(this.id.area).then((res) => {
        if(res.code === 200) {
          this.form =  {
            id: 0,
            num: parseInt(res.data),
            name: ''
          }
          this.visible1 = true;
          setTimeout(() => {
            this.$refs.input.focus();
          }, 5);
        } else {
          this.$error({
            title: this.$t('elevatorTimetable.a28'),
            centered: true,
          });
        }
      })
    },
    // 时间表添加确认按钮
    addHandleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            ls_mti_week: [1, 1, 1, 1, 1, 0, 0],
            mti_ID: 0,
            mti_areaId: this.id.area,
            mti_clientId: this.id.client,
            mti_date_end:  moment('2099-12-31T15:59:59.000Z'),
            mti_date_start: new Date(),
            mti_name: this.form.name,
            mti_num: this.form.num,
            mti_time1_endStr: "17:00:00",
            mti_time1_startStr: "08:00:00",
            mti_time2_endStr: "00:00:00",
            mti_time2_startStr: "00:00:00",
            mti_time3_endStr: "00:00:00",
            mti_time3_startStr: "00:00:00",
            mti_time4_endStr: "00:00:00",
            mti_time4_startStr: "00:00:00"
          }
          addTimeInterval(data).then( res => {
            if(res.code === 200){
              this.navderList()
              setTimeout(() => {
                this.visible1 = false;
              }, 5);
            }else {

            }
          })} else {
              return false;
        }
      });
    },
    // 时间表添加取消按钮
    addHandleCancel() {
      this.visible1 = false;
    },
    //************************* */
    // 打开关联对话框
    showConnectModel(){
      this.connectForm.devices = []
      // this.connectdeviceslist.forEach((item)=>{
      //   this.connectForm.devices.push(item.mcid)
      // }) 
      this.connectVisible = true;
    },
    // 取消关联对话框
    hideConnectModal(){
      this.connectVisible = false;
    },
    updateConnect(){
      let arr = this.connectForm.devices;
      putConnectDeviceByTimeInterval(this.timeid,arr).then(res=>{
        if(res.code === 200 ){
          this.getDeviceData();
        }else{

        }
      })
      this.connectVisible = false;
    },
    hanlderApplice(){
      let arry = []
      this.connectdeviceslist.forEach((item)=>{
        arry.push(item.mcid)
      })
      this.loading = true;
      setTimeIntervalsBatchToDevices(this.timeid,255,arry).then((res)=>{
        if(res.code === 200){
          this.$error({
            title: this.$t('elevatorTimetable.a29'),
            centered: true,
            content: res.msg
          });
        } else{
          this.$error({
            title: this.$t('elevatorTimetable.a30'),
            centered: true,
            content: res.msg
          });
        }
        this.loading = false;
        this.getData();
      }).catch((err)=>{
        console.log(err)
        this.loading = false;
      })
    }
  },
  async created() {
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
    }).catch((err) => {
        console.log("访问站点转换出错", err);
    });
    await this.navderList();
    await this.getdeviceList();
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": Navdar,
    "my-headertitle": headerTitle,
    "my-timetable": timetableModule,
    "my-timedevice": timedeviceModule,
  },
};
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
#elevatorTimetable {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
}
.aside-action{
  padding: 10px 10px 15px 20px;
}
.aside-menu {
  height: calc(100% - 58px);;
  overflow: auto;
  border-right:1px solid rgba(12, 12, 12, 0.1);
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.modelName {
  height: 80px;
  display: flex;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.footer button {
  margin: 0px 10px;
}
</style>