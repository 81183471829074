<!--时间条组件-->
<template>
  <div id="time">
    <div class="container">
      <div class="verticalorigin"></div>
      <div class="origin">
        <span>00:00</span>
      </div>
      <div class="verticalmidpoint"></div>
      <div class="midpoint">
        <span>12:00</span>
      </div>
      <div class="verticaldestination"></div>
      <div class="destination">
        <span>24:00</span>
      </div>
      <a-tooltip v-for="(itme, index) in show" :key="index">
        <template slot="title">
          <span>{{ `${itme.begin}~${itme.end}` }}</span>
        </template>
        <div class="showTime" :style="itme.style"></div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      show: [],
    };
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
          let reveal = [];
          for (let i in newVal) {
            if (i !== "patterns" && this.data[i] !== null) {
              let begin = newVal[i].begin;
              let end = newVal[i].end;
              let state = newVal[i].model;
              let style = this.renderingCalculation(newVal[i]);
              reveal.push({ begin, end, state, style });
            }
          }
          this.show = reveal
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 渲染计算方法
    renderingCalculation(value) {
      let blo = true
      const second = 86400
      const data = {}
      let begin = value.begin
      if (begin !== '') {
        const beginHour = value.begin.split(":")[0]
        const beginMinute = value.begin.split(":")[1]
        if(beginHour == '--' || beginMinute == '--'){
          blo = false
        }else{
          begin = parseInt(beginHour) * 60 * 60 +  parseInt(beginMinute) * 60
        }
      }
      let end = value.end
      if (end !== '') {
        const beginHour = value.end.split(":")[0]
        const beginMinute = value.end.split(":")[1]
        if(beginHour == '--' || beginMinute == '--'){
          blo = false
        }else{
          end = parseInt(beginHour) * 60 * 60 +  parseInt(beginMinute) * 60
        }
      }
      if(blo){
        data.width = `${((end - begin) / second) * 100}%`;
        data.left = `${(begin / second) * 100}%`;
        if (value.model == 0) {
          data.background = "#0FCE0F";
        } else if (value.model == 1) {
          data.background = "#f8b62d";
        } else if (value.model == 2) {
          data.background = "#eeee00";
        } else if (value.model == 3) {
          data.background = "#7682ce";
        } else if (value.model == 4) {
          data.background = "#00a0e9";
        }
      }else{
        data.width = '0%'
        data.left = '0%'
      }
      return data;
    },
  },
};
</script>

<style scoped>
#time {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.container {
  height: 10px;
  width: 100%;
  background: #f8b62d;
  position: relative;
}
.showTime {
  position: absolute;
  height: 10px;
  transition: 200ms ease;
}
.showTime:hover {
  transform: scaleY(1.4);
}
.origin {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 15px;
  left: -18px;
  font-size: 16px;
}
.midpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 20px;
  left: calc(50% - 19px);
  font-size: 16px;
}
.destination {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 15px;
  left: calc(100% - 20px);
  font-size: 16px;
}
.verticalorigin {
  position: absolute;
  top:10px;
  width: 2px;
  height: 10px;
  background: rgba(12,12,12,0.35);
}
.verticalmidpoint{
  position: absolute;
  top:10px;
  left: calc(50% - 1px);
  width: 2px;
  height: 10px;
  background: rgba(12,12,12,0.35);
}
.verticaldestination{
  position: absolute;
  top:10px;
  right:0;
  width: 2px;
  height: 10px;
  background: rgba(12,12,12,0.35);
}
</style>