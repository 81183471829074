<!--电梯模块--电梯时间表--时间表变更弹出框--->
<template>
  <div id="dialog" ref="dialog">
    <a-modal
      :title="$t('timetableModuleDT.a2')"
      :getContainer="() => this.$refs.dialog"
      :width="700"
      centered
      :visible="visible"
      :destroyOnClose="true"
      @cancel="handleCancel"
      v-drag
    >
      <template slot="footer">
        <a-button
          key="submit"
          :disabled="cancel_disabled"
          @click="handleCancel"
        >
          {{ $t("timetableModuleDT.a12") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="handleOk"
          :disabled="confirm_disabled"
        >
          {{ $t("timetableModuleDT.a11") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('timetableModuleDT.a1')">
        <div class="dialog-name">
          <span>{{ $t('timetableModuleDT.a3')+'：'+ $t('timetableModuleDT.a4') }}</span>
          <span>{{ $t('timetableModuleDT.a5')+'：'+  data.mti_name  }}</span>
          <span>{{ $t('timetableModule.a12') + `: ${ moment(data.mti_date_start).format(`YYYY`+ $t('timetableModule.a16') + 'MM'+ $t('timetableModule.a17') + 'DD' + $t('timetableModule.a18')) } ${$t('timetableModule.a19')} ${ moment(data.mti_date_end).format(`YYYY`+ $t('timetableModule.a16') + 'MM'+ $t('timetableModule.a17') + 'DD' + $t('timetableModule.a18')) }` }}</span>
        </div>
        <div style="display:flex;">
          <ul class="templatetitle-right">
              <li>
                {{ $t('timetableModuleDT.a6') }}
              </li>
              <li>
                “
                <div class="upright" style="background: #f8b62d"></div>
                {{ $t('timetableModuleDT.a7') }}”{{ $t('timetableModuleDT.a8') }}
              </li>
          </ul>
        </div>
        <div class="dialog-time">
          <div class="dialog-time-row">
            <div>
              <span>{{ $t("timetableModule.a10") }}1</span>
            </div>
            <div>
              <a-select v-model="openData.schedule1.begin[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule1.begin[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule1.begin[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <span>~</span>
            </div>
            <div>
             <a-select v-model="openData.schedule1.end[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule1.end[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule1.end[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <a-select v-model="openData.schedule1.model" style="width: 200px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in mode.patterns"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="dialog-time-row">
            <div>
              <span>{{ $t("timetableModule.a10") }}2</span>
            </div>
            <div>
              <a-select v-model="openData.schedule2.begin[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule2.begin[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule2.begin[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <span>~</span>
            </div>
            <div>
             <a-select v-model="openData.schedule2.end[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule2.end[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule2.end[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <a-select v-model="openData.schedule2.model" style="width: 200px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in mode.patterns"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="dialog-time-row">
            <div>
              <span>{{ $t("timetableModule.a10") }}3</span>
            </div>
            <div>
              <a-select v-model="openData.schedule3.begin[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule3.begin[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule3.begin[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <span>~</span>
            </div>
            <div>
             <a-select v-model="openData.schedule3.end[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule3.end[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule3.end[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <a-select v-model="openData.schedule3.model" style="width: 200px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in mode.patterns"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="dialog-time-row">
            <div>
              <span>{{ $t("timetableModule.a10") }}4</span>
            </div>
            <div>
              <a-select v-model="openData.schedule4.begin[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule4.begin[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule4.begin[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <span>~</span>
            </div>
            <div>
             <a-select v-model="openData.schedule4.end[0]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in hour"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a9') }}
              <a-select v-model="openData.schedule4.end[1]" style="width: 60px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in minute"
                  :key="item"
                  :disabled="openData.schedule4.end[0]=='24' && item !== '00'"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
              {{ $t('timetableModuleDT.a10') }}
            </div>
            <div>
              <a-select v-model="openData.schedule4.model" style="width: 200px" :getPopupContainer="(trigger) => trigger.parentElement">
                <a-select-option
                  v-for="item in mode.patterns"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div class="dialog-canvas">
          <my-showtime :data="canvasData" v-if="visible"></my-showtime>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import showTime from "../components/showTime";
import { doorTtUpdate } from "../../../api/door";
import moment from "moment";
import { setTimeInterval } from "../../../api/elevator";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    // name: {
    //   type: String,
    //   default: "",
    // },
    // dialogType: {
    //   type: String,
    //   default: "",
    // },
    // week: {
    //   type: Number,
    // },
    // scheId: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      confirm_disabled: false,
      cancel_disabled: false,
      loading: false,
      canvasData: {},
      hour:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
      minute:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
      openData: {
        schedule1: {
          begin: ['--','--'],
          end: ['--','--'],
          model:1
        },
        schedule2: {
          begin: ['--','--'],
          end: ['--','--'],
          model:1
        },
        schedule3: {
          begin: ['--','--'],
          end: ['--','--'],
          model:1
        },
        schedule4: {
          begin: ['--','--'],
          end: ['--','--'],
          model:1
        },
      },
      mode:{
        patterns:[ {value:0, name:this.$t('timetableModuleDT.a13')}]
      }
    };
  },
  watch: {
    // 操作修改显示时间数据
    data: {
      handler(newVal, oldVal) {

        const blo = newVal.mti_time1_startStr;
        if(blo !== undefined){
          let data = JSON.stringify(this.data);
          let _data = {
            schedule1: {
              begin: [this.data.mti_time1_startStr.split(":")[0],this.data.mti_time1_startStr.split(":")[1]],
              end: [this.data.mti_time1_endStr.split(":")[0],this.data.mti_time1_endStr.split(":")[1]],
              model:0
            },
            schedule2: {
              begin: [this.data.mti_time2_startStr.split(":")[0],this.data.mti_time2_startStr.split(":")[1]],
              end: [this.data.mti_time2_endStr.split(":")[0],this.data.mti_time2_endStr.split(":")[1]],
              model:0
            },
            schedule3: {
              begin: [this.data.mti_time3_startStr.split(":")[0],this.data.mti_time3_startStr.split(":")[1]],
              end: [this.data.mti_time3_endStr.split(":")[0],this.data.mti_time3_endStr.split(":")[1]],
              model:0
            },
            schedule4: {
              begin: [this.data.mti_time4_startStr.split(":")[0],this.data.mti_time4_startStr.split(":")[1]],
              end: [this.data.mti_time4_endStr.split(":")[0],this.data.mti_time4_endStr.split(":")[1]],
              model:0
            },
          }
          this.openData = _data;
        }
      },
      deep: true,
    },
    // 渲染时间条
    openData: {
      handler(newVal, oldVal) {
        for(let i in newVal){
          if(newVal[i].begin[0] == '24'){
            newVal[i].begin[1] = '00'
          }
          if(newVal[i].end[0] == '24'){
            newVal[i].end[1] = '00'
          }
        }
        let data = JSON.stringify(this.openData)
        let _data = JSON.parse(data)
        for(let i in _data){
          _data[i].begin = _data[i].begin[0] + ':' +  _data[i].begin[1]
          _data[i].end = _data[i].end[0] + ':' + _data[i].end[1]
        }
        this.canvasData = _data;
      },
      deep: true,
    },
  },
  computed: {
  },
  methods: {
    moment,
    // 对话框的确定按钮
    handleOk() {
      let affirm = true; // 执行报错还是接口请求的判断值 
      const _data = JSON.stringify(this.openData)
      const data = JSON.parse(_data)
      let arr = []; // 大数组
      // 将所有时间段做成数组pushu到arr数组
      for (let i in data) {
        let time = []
        const _begin1 = data[i].begin[0] * 60
        const _begin2 = parseInt(data[i].begin[1])
        const _end1 = data[i].end[0] * 60
        const _end2 = parseInt(data[i].end[1])
        if(!isNaN(_begin1) || !isNaN(_begin2)){
          if(isNaN(_begin1) || isNaN(_begin2)){
            affirm = false
          }else{
            time.push(_begin1 + _begin2)
          }
        }
        if(!isNaN(_end1) || !isNaN(_end2)){
          if(isNaN(_end1) || isNaN(_end2)){
            affirm = false
          }else{
            time.push(_end1 + _end2)
          }
        }
        arr.push(time)
      }
      // 以下方法判断每个时间段是否都填写开始时间和结束时间，否affirm = false
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length == 1) {
          affirm = false;
        } else if (arr[i].length == 2) {
          if (arr[i][0] >= arr[i][1]) {
            if( arr[i][0] + arr[i][1] != 0 )
            affirm = false;
          }
        }
      }
      // // 以下方法判断每个时间段是否存在交叉（重复）的情况，是affirm = false
      for (let i = 0; i < arr.length; i++) {
        let a = arr[i][0];
        for (let j = 0; j < arr.length; j++) {
          if (i !== j) {
            if (a > arr[j][0] && a < arr[j][1]) {
              affirm = false;
            }
            // 增加判断起始时间未设置，继续判断结束时间是否为未设置，若存在两个以上设置判定为交叉重复
            if (a + arr[j][0] === 0 && arr[i][1] !== 0 && arr[j][1] !== 0) {
              affirm = false;
            }
          }
        }
      }
      // 以下方法判断affirm = false还是true，false报错，true执行接口请求
      if (affirm) {
        this.$confirm({
          title: this.$t("doortimetable.b16"),
          centered: true,
          onOk: () => {
            this.loading = true;
            this.confirm_disabled = true;
            this.cancel_disabled = true;
            let scheId = this.scheId;
            let scheType = this.dialogType;
            let day = this.week;
            let scheduleItems = [];
            const openData = this.openData
            for (let i in openData) {
              const begin1 = openData[i].begin[0]
              const begin2 = openData[i].begin[1]
              const end1 = openData[i].end[0]
              const end2 = openData[i].end[1]
              const model = openData[i].model
              let begin = ''
              let end = ''
              if(begin1 == '--' || begin2 == '--'){
                begin = ''
              }else{
                begin = begin1 + ':' + begin2 + ':' + '00'
              }
              if(end1 == '--' || end2 == '--'){
                end = ''
              }else{
                end = end1 + ':' + end2 + ':' + '00'
              }
              scheduleItems.push({ begin, end, model });
            }
            const form = this.data;
            form.mti_time1_startStr = scheduleItems[0].begin;
            form.mti_time1_endStr = scheduleItems[0].end;
            form.mti_time2_startStr = scheduleItems[1].begin;
            form.mti_time2_endStr = scheduleItems[1].end;
            form.mti_time3_startStr = scheduleItems[2].begin;
            form.mti_time3_endStr = scheduleItems[2].end;
            form.mti_time4_startStr = scheduleItems[3].begin;
            form.mti_time4_endStr = scheduleItems[3].end;
            setTimeInterval(form)
              .then((res) => {
                this.loading = false;
                this.confirm_disabled = false;
                this.cancel_disabled = false;
                if (res.code == 200) {
                  this.$parent.closeDialog();
                  this.$parent.getData();
                  this.$message.success(res.msg);
                } else{
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                this.confirm_disabled = false;
                this.cancel_disabled = false;
              });
          },
        })
      } else {
        this.$error({
          title: this.$t("doortimetable.b17"),
          centered: true,
          onOk: () => {},
        });
      }
    },
    // 对话框的取消按钮
    handleCancel() {
      this.$parent.closeDialog();
      // this.Reset();
    },
  },
  mounted(){
    
  },
  components: {
    "my-showtime": showTime,
  },
};
</script>

<style>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.dialog-name span {
  margin-right: 30px;
}
.dialog-time {
  border-top: 1px solid #cccdcd;
  border-bottom: 1px solid #cccdcd;
  padding: 10px 0;
  margin-top: 10px;
}
.dialog-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dialog-canvas {
  width: 100%;
  height: 80px;
  padding: 10px 20px;
}
.dialog-button {
  padding-top: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
</style>